import * as React from 'react';
import { useRouter } from 'next/router';

import {
  GeneralStrings,
  FutureAccountStrings,
  TransactionStrings,
  TermAccountStrings,
  IndexedSavingsAccountsStrings,
} from '@kvika/audur-strings';
import { getFutureAccounts } from '@kvika/audur-utils';

import Tooltip from '@kvika/tooltip';
import styled from 'styled-components';
import { usePathname } from 'next/navigation';
import MenuDrawer from '../features/navigation/MenuDrawer';
import ProfileIcon from '../features/navigation/ProfileIcon';
import { Colors } from '../../dls/Styleguide';
import { useAppContext } from '../../store/AppContext';
import { DEFAULT_NAV_ACCOUNT_LINKS, Links } from '../../dls/DataConstants';
import { StyledClickable } from '../styledComponents/ClickableStyles';
import {
  StyledImage,
  StyledImageLink,
  StyledLink,
  StyledLinkText,
  StyledNavContainer,
  StyledNavContent,
  StyledNavLinks,
  StyledRightContent,
  StyledTooltipContent,
} from './NavigationStyles';
import DropDownLink from './DropDownLink';
import FeatureWrapper from '../development/FeatureWrapper';
import { FeatureFlag } from '../../types/Types';
import FontWrapper from '../../styles/FontWrapper';

type DropDownListProps = { currentPath: string };

const DropDownList = ({ currentPath }: DropDownListProps) => {
  return (
    <>
      {DEFAULT_NAV_ACCOUNT_LINKS.map((link) => (
        <DropDownLink key={link.path} path={link.path} title={link.title} currentPath={currentPath} />
      ))}
    </>
  );
};

export const StyledTooltip = styled(Tooltip)`
  background-color: ${Colors.Beige};
`;

const Navigation = () => {
  const { state } = useAppContext();
  const { userProfile, accounts } = state;
  const { user, usersChildren, closedAccounts, isCompanyEntity, access } = state;
  const router = useRouter();
  const pathname = usePathname();

  const currentPath = router.pathname;

  const hasFutureAccounts = getFutureAccounts(accounts).length > 0;
  const nonNavigationPathnames = [
    Links.Login,
    Links.ConfirmAML,
    Links.OverviewAML,
    Links.CreateAccount,
    Links.LegalEntityOnboarding,
    Links.ChooseAccount,
    Links.PEPUser,
    Links.IsPEP,
    Links.IdentityConfirmation,
    Links.ThankYouForConfirming,
  ];
  const isNavigationPathname = !nonNavigationPathnames.find((link) => pathname === link);

  const showNavigation = isNavigationPathname && user;
  const showFutureAccountsLink =
    (usersChildren && usersChildren.length > 0) || userProfile?.isFutureAccountEligible || hasFutureAccounts;
  const showClosedAccountsLink = closedAccounts.length > 0;

  return showNavigation ? (
    <StyledNavContainer>
      <StyledImageLink href={Links.Home}>
        <StyledImage src="/svg/audur_logo.svg" width={136} height={75} alt="Audur logo" priority />
      </StyledImageLink>
      <StyledNavContent>
        <StyledNavLinks>
          <StyledLink href={Links.Transfer}>
            <StyledLinkText text={TransactionStrings.Transfer} bold selected={currentPath === Links.Transfer} />
          </StyledLink>
          <StyledTooltip
            id="accounts"
            delayedHide
            content={
              <FontWrapper>
                <StyledTooltipContent>
                  <DropDownList currentPath={currentPath} />
                  {showFutureAccountsLink && (
                    <DropDownLink
                      path={Links.FutureAccounts.Default}
                      title={FutureAccountStrings.FutureAccounts}
                      currentPath={currentPath}
                    />
                  )}
                  {showClosedAccountsLink && (
                    <DropDownLink
                      path={Links.ClosedAccounts}
                      title={TermAccountStrings.ClosedAccounts}
                      currentPath={currentPath}
                    />
                  )}
                  <FeatureWrapper featureFlag={FeatureFlag.isIndexedSavingsAccountsEnabled}>
                    <DropDownLink
                      path={Links.IndexedSavingsAccounts.Default}
                      title={IndexedSavingsAccountsStrings.IndexedSavingsAccounts}
                      currentPath={currentPath}
                    />
                  </FeatureWrapper>
                </StyledTooltipContent>
              </FontWrapper>
            }
          >
            {!isCompanyEntity && (
              <StyledClickable>
                <StyledLinkText text={GeneralStrings.Accounts} bold selected={false} />
              </StyledClickable>
            )}
          </StyledTooltip>
        </StyledNavLinks>
        <StyledRightContent>
          <ProfileIcon userName={access?.name} />
          <MenuDrawer
            userName={access?.name}
            showFutureAccountsLink={showFutureAccountsLink}
            showClosedAccountsLink={showClosedAccountsLink}
          />
        </StyledRightContent>
      </StyledNavContent>
    </StyledNavContainer>
  ) : null;
};

export default Navigation;
