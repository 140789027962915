import * as Sentry from '@sentry/nextjs';
import { ApiError as KvikaApiError } from '@kvika/api-client';
import { ApiError as AudurApiError } from '@kvika/audur-api-client-v2';
import { setTag } from '@sentry/nextjs';

export enum SentryErrors {
  ResponseInvalid = 'Login Response is not valid, no authentication token exists',
  UserProfileInvalid = 'User profile was not found, no ssn exists in response',
  CreateTermDepositAccountError = 'Error when creating a Term Deposit Account',
  TransactionsError = 'Transactions have an invalid response, not a valid array',
  TransferError = 'Transfer response is invalid',
  LogoutError = 'Logout user failed',
  UpdateUserError = 'Update user failed',
  AccountValidationError = 'Error in account validation',
  RegisterUserError = 'Register user error, response does not contain isPEP',
  CountriesError = 'Error in getCountryList',
  GetAccountsError = 'Error in fetching Accounts',
  NoInterestSummary = 'No interest summary found',
  NoProductsFound = 'No products found',
  ResetUserError = 'Could not reset user',
  PepError = 'Error signing PEP document',
  NoPepIdFound = 'No PEP id was present',
  UsersChildrenError = "Could not get user's children",
  GetFutureAccountsSurveyError = 'Could not get future account survey for child',
  CreatePEPChildDocumentError = 'Error when creating PEP child document',
  SignDocumentError = 'Could not sign document',
  CreateFutureAccountError = 'Error when creating a future account',
  SubmitFutureAccountSurveyError = 'Could not submit future account survey for child',
  GetUsersCurrentSessionError = "Error getting user's current session",
  AlreadyRegistered = 'You are already registered',
  SubmitOnboardingSurveyError = 'Could not submit onboarding survey',
  SubmitRecurringAMLSurveyError = 'Could not submit recurring AML survey',
  UpdateAddressError = 'Could not update address',
  GetRecurringAMLSurveyError = 'Could not get recurring AML survey',
  GetOnboardingSurveyError = 'Could not get onboarding survey',
  GetLastAnswersError = 'Could not get last answers',
  PostDocumentSignatureError = 'Could not post document signature',
  GetDocumentSignatureStatusError = 'Could not get document signature status',
  GetUserProfileError = 'Could not get user profile',
  GetTestSentryError = 'Get test sentry error',
  PostNoticeError = 'Could not create notice',
  GetClosedAccountsError = 'Could not get closed accounts',
  GetNoticesError = 'Could not get notices',
  GetCompanyInfo = 'Could not get current company',
  GetPreviousAnswersError = 'Could not get previous answers',
}

export const getSentryEvent = (
  apiError: AudurApiError | KvikaApiError | undefined,
  errorString: SentryErrors
): Sentry.Event => {
  if (apiError && apiError.response) {
    const { status, data, headers } = apiError.response;
    if (data && headers) {
      const requestId = headers['x-request-id'];
      setTag('requestId', requestId);
      const { type, code, detail } = data;
      const detailString = typeof detail === 'string' ? detail : JSON.stringify(detail);
      return {
        message: `${detailString}, errorCode: ${status}}`,
        extra: { code, type, requestId },
      };
    }
  }
  // Reset tag if we have a unexpected error so that it doesn't get the requestId tag from the previous error
  setTag('requestId', undefined);
  return { message: errorString };
};

export const setSentryUser = (id: string, email: string | undefined) => {
  Sentry.setUser({ id, email });
};
